// $body-bg: #000;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~react-datepicker/dist/react-datepicker.css";

// $theme-colors: (
//   "primary": red,
// );

@import "~bootstrap/scss/bootstrap.scss";

@import "./includes/player.scss";
@import "./includes/sidebar.scss";
@import "./includes/list.scss";
@import "./includes/dropzone.scss";
@import "./includes/mixin.scss";
@import "./includes/position.scss";

.treeview .list-group-item {
  cursor: pointer;
}

.treeview span.indent {
  margin-left: 10px;
  margin-right: 10px;
}

.treeview span.icon {
  width: 12px;
  margin-right: 5px;
}

.treeview .node-disabled {
  color: silver;
  cursor: not-allowed;
}

.position-absolute-full {
  @extend .position-absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin width {
  width: auto !important;
}

table {
  &.table-fit {
    @include width;
    table-layout: auto !important;
    thead th,
    tfoot th {
      @include width;
    }
    tbody td,
    tfoot td {
      @include width;
    }
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.table > tbody > tr > th {
  vertical-align: middle;
}
