@mixin aspect-ratio($width, $height) {
  @extend .position-relative;

  &:before {
    content: "";
    @extend .w-100;
    @extend .d-block;
    padding-top: ($height / $width) * 100%;
  }

  > .inner {
    @extend .position-absolute-0;
  }
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-16-9 {
  @include aspect-ratio(16, 9);
}

.bg-4-3 {
  @include aspect-ratio(16, 9);
}

.bg-32-9 {
  @include aspect-ratio(32, 9);
}
