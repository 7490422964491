.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}

.list-group.list-group-root .list-group {
  margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
  padding-left: 30px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 45px;
}

.list-group.list-group-root
  > .list-group
  > .list-group
  > .list-group
  > .list-group-item {
  padding-left: 60px;
}

.list-group.list-group-root
  > .list-group
  > .list-group
  > .list-group
  > .list-group
  > .list-group-item {
  padding-left: 75px;
}

.list-group-item {
  margin-right: 5px;
}

// .list-group-item.active {
//   background-color: $text-muted !important;
// }
